export const tiles = [
    {id: 'A1',tile: "I1001",},
    {id: 'A2',tile: "I1000",},
    {id: 'A3',tile: "I1000",},
    {id: 'A4',tile: "I1100",},
    {id: 'A5',tile: "E1011",},
    {id: 'A6',tile: "E1100",},
    {id: 'A7',tile: "E1001",},
    {id: 'A8',tile: "E1100",},
    {id: 'A9',tile: "E1001",},
    {id: 'A10',tile: "E1100",},
    {id: 'A11',tile: "E1001",},
    {id: 'A12',tile: "E1010",},
    {id: 'A13',tile: "E1010",},
    {id: 'A14',tile: "E1010",},
    {id: 'A15',tile: "E1010",},
    {id: 'A16',tile: "E1000",},
    {id: 'A17',tile: "E1010",},
    {id: 'A18',tile: "E1010",},
    {id: 'A19',tile: "E1010",},
    {id: 'A20',tile: "E1100",},
    {id: 'A21',tile: "E1001",},
    {id: 'A22',tile: "E1010",},
    {id: 'A23',tile: "E1010",},
    {id: 'A24',tile: "E1110",},
    {id: 'A25',tile: "I1001",},
    {id: 'A26',tile: "I1100",},
    {id: 'B1',tile: "I0001",},
    {id: 'B2',tile: "I0000",},
    {id: 'B3',tile: "I0000",},
    {id: 'B4',tile: "I0000",},
    {id: 'B5',tile: "I1100",},
    {id: 'B6',tile: "E0011",},
    {id: 'B7',tile: "E0110",},
    {id: 'B8',tile: "E0011",},
    {id: 'B9',tile: "E0110",},
    {id: 'B10',tile: "E0011",},
    {id: 'B11',tile: "E0100",},
    {id: 'B12',tile: "E1001",},
    {id: 'B13',tile: "E1010",},
    {id: 'B14',tile: "E1010",},
    {id: 'B15',tile: "E1010",},
    {id: 'B16',tile: "E0110",},
    {id: 'B17',tile: "E1001",},
    {id: 'B18',tile: "E1010",},
    {id: 'B19',tile: "E1110",},
    {id: 'B20',tile: "E0011",},
    {id: 'B21',tile: "E0100",},
    {id: 'B22',tile: "I1001",},
    {id: 'B23',tile: "I1000",},
    {id: 'B24',tile: "I1000",},
    {id: 'B25',tile: "I0000",},
    {id: 'B26',tile: "I0100",},
    {id: 'C1',tile: "I0001",},
    {id: 'C2',tile: "V",},
    {id: 'C3',tile: "I0000",},
    {id: 'C4',tile: "I0000",},
    {id: 'C5',tile: "I0000",},
    {id: 'C6',tile: "I1000",},
    {id: 'C7',tile: "I1100",},
    {id: 'C8',tile: "E1001",},
    {id: 'C9',tile: "E1010",},
    {id: 'C10',tile: "E1010",},
    {id: 'C11',tile: "E0100",},
    {id: 'C12',tile: "E0011",},
    {id: 'C13',tile: "E1010",},
    {id: 'C14',tile: "E1010",},
    {id: 'C15',tile: "E1010",},
    {id: 'C16',tile: "E1100",},
    {id: 'C17',tile: "E0101",},
    {id: 'C18',tile: "I1001",},
    {id: 'C19',tile: "I1000",},
    {id: 'C20',tile: "I1010",},
    {id: 'C21',tile: "B1010II",},
    {id: 'C22',tile: "I0000",},
    {id: 'C23',tile: "I0000",},
    {id: 'C24', tile: "I0000",},
    {id: 'C25', tile: "I0000",},
    {id: 'C26', tile: "I0100",},
    {id: 'D1', tile: "I0011",},
    {id: 'D2', tile: "I0010",},
    {id: 'D3', tile: "I0000",},
    {id: 'D4', tile: "I0000",},
    {id: 'D5', tile: "V",},
    {id: 'D6', tile: "I0000",},
    {id: 'D7', tile: "I0000",},
    {id: 'D8', tile: "I0000",},
    {id: 'D9', tile: "I1000",},
    {id: 'D10', tile: "I1100",},
    {id: 'D11', tile: "E0101",},
    {id: 'D12', tile: "E1001",},
    {id: 'D13', tile: "E1010",},
    {id: 'D14', tile: "E1010",},
    {id: 'D15', tile: "E1010",},
    {id: 'D16', tile: "E0010",},
    {id: 'D17', tile: "E0110",},
    {id: 'D18', tile: "I0001",},
    {id: 'D19', tile: "I0110",},
    {id: 'D20', tile: "E1001",},
    {id: 'D21', tile: "E0110",},
    {id: 'D22', tile: "I0001",},
    {id: 'D23', tile: "I0000",},
    {id: 'D24', tile: "V",},
    {id: 'D25', tile: "I0000",},
    {id: 'D26', tile: "I0100",},
    {id: 'E1', tile: "E1001",},
    {id: 'E2', tile: "E1100",},
    {id: 'E3', tile: "I0011",},
    {id: 'E4', tile: "I0000",},
    {id: 'E5', tile: "I0000",},
    {id: 'E6', tile: "I0000",},
    {id: 'E7', tile: "I0000",},
    {id: 'E8', tile: "I0000",},
    {id: 'E9', tile: "I0000",},
    {id: 'E10', tile: "I0100",},
    {id: 'E11', tile: "E0101",},
    {id: 'E12', tile: "E0111",},
    {id: 'E13', tile: "E1001",},
    {id: 'E14', tile: "E1110",},
    {id: 'E15', tile: "I1001",},
    {id: 'E16', tile: "I1000",},
    {id: 'E17', tile: "I1000",},
    {id: 'E18', tile: "I0110",},
    {id: 'E19', tile: "E1011",},
    {id: 'E20', tile: "E0010",},
    {id: 'E21', tile: "E1100",},
    {id: 'E22', tile: "I0001",},
    {id: 'E23', tile: "I0000",},
    {id: 'E24', tile: "I0000",},
    {id: 'E25', tile: "I0000",},
    {id: 'E26', tile: "I0110",},
    {id: 'F1', tile: "E0111",},
    {id: 'F2', tile: "E0011",},
    {id: 'F3', tile: "E1100",},
    {id: 'F4', tile: "I0011",},
    {id: 'F5', tile: "I0000",},
    {id: 'F6', tile: "I0000",},
    {id: 'F7', tile: "I0000",},
    {id: 'F8', tile: "V",},
    {id: 'F9', tile: "I0000",},
    {id: 'F10', tile: "I0100",},
    {id: 'F11', tile: "E0101",},
    {id: 'F12', tile: "E1001",},
    {id: 'F13', tile: "E0110",},
    {id: 'F14', tile: "I1001",},
    {id: 'F15', tile: "I0000",},
    {id: 'F16', tile: "I0000",},
    {id: 'F17', tile: "I0100",},
    {id: 'F18', tile: "E1011",},
    {id: 'F19', tile: "E1000",},
    {id: 'F20', tile: "E1010",},
    {id: 'F21', tile: "E0110",},
    {id: 'F22', tile: "I0001",},
    {id: 'F23', tile: "I0000",},
    {id: 'F24', tile: "I0000",},
    {id: 'F25', tile: "I0110",},
    {id: 'F26', tile: "E1101",},
    {id: 'G1', tile: "E1001",},
    {id: 'G2', tile: "E1100",},
    {id: 'G3', tile: "E0011",},
    {id: 'G4', tile: "E1100",},
    {id: 'G5', tile: "I0001",},
    {id: 'G6', tile: "I0000",},
    {id: 'G7', tile: "I0000",},
    {id: 'G8', tile: "I0000",},
    {id: 'G9', tile: "I0000",},
    {id: 'G10', tile: "I0100",},
    {id: 'G11', tile: "E0011",},
    {id: 'G12', tile: "E0110",},
    {id: 'G13', tile: "I1001",},
    {id: 'G14', tile: "I0000",},
    {id: 'G15', tile: "I0000",},
    {id: 'G16', tile: "V",},
    {id: 'G17', tile: "I0000",},
    {id: 'G18', tile: "I1100",},
    {id: 'G19', tile: "E0011",},
    {id: 'G20', tile: "E1010",},
    {id: 'G21', tile: "E1100",},
    {id: 'G22', tile: "I0001",},
    {id: 'G23', tile: "V",},
    {id: 'G24', tile: "I0100",},
    {id: 'G25', tile: "E1001",},
    {id: 'G26', tile: "E0110",},
    {id: 'H1', tile: "E0101",},
    {id: 'H2', tile: "E0001",},
    {id: 'H3', tile: "V",},
    {id: 'H4', tile: "E0100",},
    {id: 'H5', tile: "I0001",},
    {id: 'H6', tile: "I0000",},
    {id: 'H7', tile: "I0000",},
    {id: 'H8', tile: "I0000",},
    {id: 'H9', tile: "I0000",},
    {id: 'H10', tile: "I0000",},
    {id: 'H11', tile: "I1000",},
    {id: 'H12', tile: "I1000",},
    {id: 'H13', tile: "I0000",},
    {id: 'H14', tile: "I0000",},
    {id: 'H15', tile: "I0000",},
    {id: 'H16', tile: "I0000",},
    {id: 'H17', tile: "V",},
    {id: 'H18', tile: "I0000",},
    {id: 'H19', tile: "I1010",},
    {id: 'H20', tile: "I1010",},
    {id: 'H21', tile: "B1010II",},
    {id: 'H22', tile: "I0000",},
    {id: 'H23', tile: "I0000",},
    {id: 'H24', tile: "I0100",},
    {id: 'H25', tile: "E0011",},
    {id: 'H26', tile: "E1100",},
    {id: 'I1', tile: "E0101",},
    {id: 'I2', tile: "E0001",},
    {id: 'I3', tile: "E0010",},
    {id: 'I4', tile: "E0110",},
    {id: 'I5', tile: "I0001",},
    {id: 'I6', tile: "I0000",},
    {id: 'I7', tile: "V",},
    {id: 'I8', tile: "I0000",},
    {id: 'I9', tile: "I0000",},
    {id: 'I10', tile: "I0000",},
    {id: 'I11', tile: "I0000",},
    {id: 'I12', tile: "I0000",},
    {id: 'I13', tile: "I0000",},
    {id: 'I14', tile: "I0000",},
    {id: 'I15', tile: "I0000",},
    {id: 'I16', tile: "I0000",},
    {id: 'I17', tile: "I0000",},
    {id: 'I18', tile: "V",},
    {id: 'I19', tile: "E1010",},
    {id: 'I20', tile: "E1010",},
    {id: 'I21', tile: "E0100",},
    {id: 'I22', tile: "I0001",},
    {id: 'I23', tile: "I0000",},
    {id: 'I24', tile: "I0110",},
    {id: 'I25', tile: "E1001",},
    {id: 'I26', tile: "E0110",},
    {id: 'J1', tile: "E0101",},
    {id: 'J2', tile: "E0011",},
    {id: 'J3', tile: "E1100",},
    {id: 'J4', tile: "I1001",},
    {id: 'J5', tile: "I0000",},
    {id: 'J6', tile: "I0000",},
    {id: 'J7', tile: "I0000",},
    {id: 'J8', tile: "I0000",},
    {id: 'J9', tile: "I0010",},
    {id: 'J10', tile: "I0010",},
    {id: 'J11', tile: "I0010",},
    {id: 'J12', tile: "I0010",},
    {id: 'J13', tile: "I0000",},
    {id: 'J14', tile: "I0000",},
    {id: 'J15', tile: "I0000",},
    {id: 'J16', tile: "I0000",},
    {id: 'J17', tile: "I0000",},
    {id: 'J18', tile: "I0000",},
    {id: 'J19', tile: "I1000",},
    {id: 'J20', tile: "I1000",},
    {id: 'J21', tile: "B1010II",},
    {id: 'J22', tile: "I0010",},
    {id: 'J23', tile: "I0110",},
    {id: 'J24', tile: "E1001",},
    {id: 'J25', tile: "E0000",},
    {id: 'J26', tile: "E1100",},
    {id: 'K1', tile: "E0011",},
    {id: 'K2', tile: "E1100",},
    {id: 'K3', tile: "E0011",},
    {id: 'K4', tile: "I0000",},
    {id: 'K5', tile: "I0000",},
    {id: 'K6', tile: "I0000",},
    {id: 'K7', tile: "I0000",},
    {id: 'K8', tile: "I0110",},
    {id: 'K9', tile: "E1001",},
    {id: 'K10', tile: "E1000",},
    {id: 'K11', tile: "E1000",},
    {id: 'K12', tile: "E1100",},
    {id: 'K13', tile: "I0011",},
    {id: 'K14', tile: "I0010",},
    {id: 'K15', tile: "I0000",},
    {id: 'K16', tile: "I0000",},
    {id: 'K17', tile: "I0000",},
    {id: 'K18', tile: "I0000",},
    {id: 'K19', tile: "I0010",},
    {id: 'K20', tile: "I0110",},
    {id: 'K21', tile: "E0001",},
    {id: 'K22', tile: "E1000",},
    {id: 'K23', tile: "E1000",},
    {id: 'K24', tile: "E0000",},
    {id: 'K25', tile: "V",},
    {id: 'K26', tile: "E0100",},
    {id: 'L1', tile: "E1001",},
    {id: 'L2', tile: "E0110",},
    {id: 'L3', tile: "I1001",},
    {id: 'L4', tile: "I0000",},
    {id: 'L5', tile: "V",},
    {id: 'L6', tile: "I0000",},
    {id: 'L7', tile: "I0110",},
    {id: 'L8', tile: "E1011",},
    {id: 'L9', tile: "E0010",},
    {id: 'L10', tile: "V",},
    {id: 'L11', tile: "V",},
    {id: 'L12', tile: "E0010",},
    {id: 'L13', tile: "E1000",},
    {id: 'L14', tile: "E1100",},
    {id: 'L15', tile: "I0001",},
    {id: 'L16', tile: "I0000",},
    {id: 'L17', tile: "I0000",},
    {id: 'L18', tile: "I0110",},
    {id: 'L19', tile: "E1011",},
    {id: 'L20', tile: "E1010",},
    {id: 'L21', tile: "E0110",},
    {id: 'L22', tile: "E0001",},
    {id: 'L23', tile: "E0000",},
    {id: 'L24', tile: "V",},
    {id: 'L25', tile: "E0000",},
    {id: 'L26', tile: "E0100",},
    {id: 'M1', tile: "E0011",},
    {id: 'M2', tile: "E1100",},
    {id: 'M3', tile: "I0001",},
    {id: 'M4', tile: "I0000",},
    {id: 'M5', tile: "I0000",},
    {id: 'M6', tile: "I0100",},
    {id: 'M7', tile: "E1001",},
    {id: 'M8', tile: "E1010",},
    {id: 'M9', tile: "E1010",},
    {id: 'M10', tile: "E0010",},
    {id: 'M11', tile: "E0010",},
    {id: 'M12', tile: "E1110",},
    {id: 'M13', tile: "E0101",},
    {id: 'M14', tile: "E0101",},
    {id: 'M15', tile: "I0001",},
    {id: 'M16', tile: "I0000",},
    {id: 'M17', tile: "I0100",},
    {id: 'M18', tile: "E1001",},
    {id: 'M19', tile: "E1010",},
    {id: 'M20', tile: "E1010",},
    {id: 'M21', tile: "E1010",},
    {id: 'M22', tile: "E0010",},
    {id: 'M23', tile: "E0010",},
    {id: 'M24', tile: "E0010",},
    {id: 'M25', tile: "E0010",},
    {id: 'M26', tile: "E0100",},
    {id: 'N1', tile: "E1011",},
    {id: 'N2', tile: "E0110",},
    {id: 'N3', tile: "I0001",},
    {id: 'N4', tile: "I0000",},
    {id: 'N5', tile: "I0000",},
    {id: 'N6', tile: "I0100",},
    {id: 'N7', tile: "E0001",},
    {id: 'N8', tile: "E1010",},
    {id: 'N9', tile: "E1010",},
    {id: 'N10', tile: "E1010",},
    {id: 'N11', tile: "E1010",},
    {id: 'N12', tile: "E1010",},
    {id: 'N13', tile: "E0100",},
    {id: 'N14', tile: "E0101",},
    {id: 'N15', tile: "I0001",},
    {id: 'N16', tile: "I0000",},
    {id: 'N17', tile: "I0100",},
    {id: 'N18', tile: "E0001",},
    {id: 'N19', tile: "E1010",},
    {id: 'N20', tile: "E1010",},
    {id: 'N21', tile: "E1000",},
    {id: 'N22', tile: "E1100",},
    {id: 'N23', tile: "E1001",},
    {id: 'N24', tile: "E1100",},
    {id: 'N25', tile: "E1101",},
    {id: 'N26', tile: "E0101",},
    {id: 'O1', tile: "I1001",},
    {id: 'O2', tile: "I1000",},
    {id: 'O3', tile: "I0000",},
    {id: 'O4', tile: "I0000",},
    {id: 'O5', tile: "I0000",},
    {id: 'O6', tile: "I0100",},
    {id: 'O7', tile: "E0011",},
    {id: 'O8', tile: "E1100",},
    {id: 'O9', tile: "E1011",},
    {id: 'O10', tile: "E1010",},
    {id: 'O11', tile: "E1010",},
    {id: 'O12', tile: "E1100",},
    {id: 'O13', tile: "E0101",},
    {id: 'O14', tile: "I0001",},
    {id: 'O15', tile: "I0000",},
    {id: 'O16', tile: "I0000",},
    {id: 'O17', tile: "I0100",},
    {id: 'O18', tile: "E0011",},
    {id: 'O19', tile: "E1010",},
    {id: 'O20', tile: "E1100",},
    {id: 'O21', tile: "E0101",},
    {id: 'O22', tile: "E0011",},
    {id: 'O23', tile: "E0110",},
    {id: 'O24', tile: "E0101",},
    {id: 'O25', tile: "E0101",},
    {id: 'O26', tile: "E0101",},
    {id: 'P1', tile: "I0001",},
    {id: 'P2', tile: "I0000",},
    {id: 'P3', tile: "I0000",},
    {id: 'P4', tile: "V",},
    {id: 'P5', tile: "I0000",},
    {id: 'P6', tile: "I0000",},
    {id: 'P7', tile: "I1100",},
    {id: 'P8', tile: "E0011",},
    {id: 'P9', tile: "E1010",},
    {id: 'P10', tile: "E1010",},
    {id: 'P11', tile: "E1100",},
    {id: 'P12', tile: "E0011",},
    {id: 'P13', tile: "E0100",},
    {id: 'P14', tile: "I0001",},
    {id: 'P15', tile: "I0000",},
    {id: 'P16', tile: "V",},
    {id: 'P17', tile: "I0000",},
    {id: 'P18', tile: "I1000",},
    {id: 'P19', tile: "I1000",},
    {id: 'P20', tile: "E0100",},
    {id: 'P21', tile: "E0001",},
    {id: 'P22', tile: "E1010",},
    {id: 'P23', tile: "E1100",},
    {id: 'P24', tile: "E0101",},
    {id: 'P25', tile: "E0101",},
    {id: 'P26', tile: "E0101",},
    {id: 'Q1', tile: "I0001",},
    {id: 'Q2', tile: "I0000",},
    {id: 'Q3', tile: "V",},
    {id: 'Q4', tile: "I0000",},
    {id: 'Q5', tile: "I0000",},
    {id: 'Q6', tile: "I0000",},
    {id: 'Q7', tile: "I0000",},
    {id: 'Q8', tile: "I1000",},
    {id: 'Q9', tile: "I1000",},
    {id: 'Q10', tile: "I1100",},
    {id: 'Q11', tile: "E0011",},
    {id: 'Q12', tile: "E1100",},
    {id: 'Q13', tile: "E0111",},
    {id: 'Q14', tile: "I0001",},
    {id: 'Q15', tile: "V",},
    {id: 'Q16', tile: "I0000",},
    {id: 'Q17', tile: "I0000",},
    {id: 'Q18', tile: "I0010",},
    {id: 'Q19', tile: "I0010",},
    {id: 'Q20', tile: "E0100",},
    {id: 'Q21', tile: "E0101",},
    {id: 'Q22', tile: "E1101",},
    {id: 'Q23', tile: "E0101",},
    {id: 'Q24', tile: "E0011",},
    {id: 'Q25', tile: "E0110",},
    {id: 'Q26', tile: "E0101",},
    {id: 'R1', tile: "I0001",},
    {id: 'R2', tile: "I0000",},
    {id: 'R3', tile: "I0000",},
    {id: 'R4', tile: "I0000",},
    {id: 'R5', tile: "I0000",},
    {id: 'R6', tile: "I0000",},
    {id: 'R7', tile: "I0000",},
    {id: 'R8', tile: "I0000",},
    {id: 'R9', tile: "I0000",},
    {id: 'R10', tile: "I0000",},
    {id: 'R11', tile: "I1100",},
    {id: 'R12', tile: "E0101",},
    {id: 'R13', tile: "I1001",},
    {id: 'R14', tile: "I0000",},
    {id: 'R15', tile: "I0000",},
    {id: 'R16', tile: "I0000",},
    {id: 'R17', tile: "I0110",},
    {id: 'R18', tile: "E1001",},
    {id: 'R19', tile: "E1010",},
    {id: 'R20', tile: "E0110",},
    {id: 'R21', tile: "E0101",},
    {id: 'R22', tile: "E0101",},
    {id: 'R23', tile: "E0011",},
    {id: 'R24', tile: "E1010",},
    {id: 'R25', tile: "E1000",},
    {id: 'R26', tile: "E0110",},
    {id: 'S1', tile: "I0001",},
    {id: 'S2', tile: "I0000",},
    {id: 'S3', tile: "I0000",},
    {id: 'S4', tile: "I0000",},
    {id: 'S5', tile: "I0000",},
    {id: 'S6', tile: "I0000",},
    {id: 'S7', tile: "I0000",},
    {id: 'S8', tile: "V",},
    {id: 'S9', tile: "E0000",},
    {id: 'S10', tile: "I0000",},
    {id: 'S11', tile: "I0000",},
    {id: 'S12', tile: "B1010II",},
    {id: 'S13', tile: "I0000",},
    {id: 'S14', tile: "I0000",},
    {id: 'S15', tile: "I0000",},
    {id: 'S16', tile: "I0100",},
    {id: 'S17', tile: "E1001",},
    {id: 'S18', tile: "E0010",},
    {id: 'S19', tile: "E1010",},
    {id: 'S20', tile: "E1110",},
    {id: 'S21', tile: "E0101",},
    {id: 'S22', tile: "E0011",},
    {id: 'S23', tile: "E1010",},
    {id: 'S24', tile: "E1010",},
    {id: 'S25', tile: "E0100",},
    {id: 'S26', tile: "I1101",},
    {id: 'T1', tile: "I0001",},
    {id: 'T2', tile: "V",},
    {id: 'T3', tile: "I0000",},
    {id: 'T4', tile: "I0010",},
    {id: 'T5', tile: "I0010",},
    {id: 'T6', tile: "I0010",},
    {id: 'T7', tile: "I0000",},
    {id: 'T8', tile: "I0000",},
    {id: 'T9', tile: "V",},
    {id: 'T10', tile: "I0000",},
    {id: 'T11', tile: "I0100",},
    {id: 'T12', tile: "E1010",},
    {id: 'T13', tile: "I0001",},
    {id: 'T14', tile: "I0000",},
    {id: 'T15', tile: "I0000",},
    {id: 'T16', tile: "I0100",},
    {id: 'T17', tile: "E0111",},
    {id: 'T18', tile: "E1001",},
    {id: 'T19', tile: "E1010",},
    {id: 'T20', tile: "E1100",},
    {id: 'T21', tile: "E0001",},
    {id: 'T22', tile: "E1010",},
    {id: 'T23', tile: "E1010",},
    {id: 'T24', tile: "E1010",},
    {id: 'T25', tile: "E0110",},
    {id: 'T26', tile: "I0101",},
    {id: 'U1', tile: "I0001",},
    {id: 'U2', tile: "I0000",},
    {id: 'U3', tile: "V",},
    {id: 'U4', tile: "E1000",},
    {id: 'U5', tile: "E1000",},
    {id: 'U6', tile: "E1100",},
    {id: 'U7', tile: "I0011",},
    {id: 'U8', tile: "I0010",},
    {id: 'U9', tile: "I0000",},
    {id: 'U10', tile: "I0000",},
    {id: 'U11', tile: "I0000",},
    {id: 'U12', tile: "B1010II",},
    {id: 'U13', tile: "I0010",},
    {id: 'U14', tile: "I0000",},
    {id: 'U15', tile: "I0100",},
    {id: 'U16', tile: "E1011",},
    {id: 'U17', tile: "E1100",},
    {id: 'U18', tile: "E0011",},
    {id: 'U19', tile: "E1110",},
    {id: 'U20', tile: "E0101",},
    {id: 'U21', tile: "E0101",},
    {id: 'U22', tile: "I1001",},
    {id: 'U23', tile: "I1000",},
    {id: 'U24', tile: "I1000",},
    {id: 'U25', tile: "I1000",},
    {id: 'U26', tile: "I0100",},
    {id: 'V1', tile: "I0001",},
    {id: 'V2', tile: "I0000",},
    {id: 'V3', tile: "I0100",},
    {id: 'V4', tile: "E0101",},
    {id: 'V5', tile: "E0101",},
    {id: 'V6', tile: "E0011",},
    {id: 'V7', tile: "E1010",},
    {id: 'V8', tile: "E1100",},
    {id: 'V9', tile: "I0011",},
    {id: 'V10', tile: "I0010",},
    {id: 'V11', tile: "I0110",},
    {id: 'V12', tile: "E0001",},
    {id: 'V13', tile: "E1100",},
    {id: 'V14', tile: "I0001",},
    {id: 'V15', tile: "I0000",},
    {id: 'V16', tile: "I1100",},
    {id: 'V17', tile: "E0011",},
    {id: 'V18', tile: "E1010",},
    {id: 'V19', tile: "E1010",},
    {id: 'V20', tile: "E0010",},
    {id: 'V21', tile: "E0110",},
    {id: 'V22', tile: "I0001",},
    {id: 'V23', tile: "V",},
    {id: 'V24', tile: "I0000",},
    {id: 'V25', tile: "I0000",},
    {id: 'V26', tile: "I0100",},
    {id: 'W1', tile: "I0001",},
    {id: 'W2', tile: "I0000",},
    {id: 'W3', tile: "I0100",},
    {id: 'W4', tile: "E0111",},
    {id: 'W5', tile: "E0101",},
    {id: 'W6', tile: "E1001",},
    {id: 'W7', tile: "E1110",},
    {id: 'W8', tile: "E0011",},
    {id: 'W9', tile: "E1010",},
    {id: 'W10', tile: "E1010",},
    {id: 'W11', tile: "E1010",},
    {id: 'W12', tile: "E0110",},
    {id: 'W13', tile: "E0101",},
    {id: 'W14', tile: "I0011",},
    {id: 'W15', tile: "I0000",},
    {id: 'W16', tile: "I0000",},
    {id: 'W17', tile: "I1000",},
    {id: 'W18', tile: "I1000",},
    {id: 'W19', tile: "I1000",},
    {id: 'W20', tile: "I1000",},
    {id: 'W21', tile: "I1000",},
    {id: 'W22', tile: "I0000",},
    {id: 'W23', tile: "I0000",},
    {id: 'W24', tile: "I0000",},
    {id: 'W25', tile: "I0000",},
    {id: 'W26', tile: "I0100",},
    {id: 'X1', tile: "I0001",},
    {id: 'X2', tile: "V",},
    {id: 'X3', tile: "I0000",},
    {id: 'X4', tile: "I1100",},
    {id: 'X5', tile: "E0101",},
    {id: 'X6', tile: "E0011",},
    {id: 'X7', tile: "E1010",},
    {id: 'X8', tile: "E1010",},
    {id: 'X9', tile: "E1010",},
    {id: 'X10', tile: "E1010",},
    {id: 'X11', tile: "E1010",},
    {id: 'X12', tile: "E1010",},
    {id: 'X13', tile: "E0010",},
    {id: 'X14', tile: "E1100",},
    {id: 'X15', tile: "I0001",},
    {id: 'X16', tile: "I0000",},
    {id: 'X17', tile: "I0000",},
    {id: 'X18', tile: "V",},
    {id: 'X19', tile: "I0000",},
    {id: 'X20', tile: "I0000",},
    {id: 'X21', tile: "I0000",},
    {id: 'X22', tile: "I0000",},
    {id: 'X23', tile: "I0000",},
    {id: 'X24', tile: "V",},
    {id: 'X25', tile: "I0000",},
    {id: 'X26', tile: "I0100",},
    {id: 'Y1', tile: "I0001",},
    {id: 'Y2', tile: "I0000",},
    {id: 'Y3', tile: "I0000",},
    {id: 'Y4', tile: "I0100",},
    {id: 'Y5', tile: "E0011",},
    {id: 'Y6', tile: "E1000",},
    {id: 'Y7', tile: "E1100",},
    {id: 'Y8', tile: "E1001",},
    {id: 'Y9', tile: "E1100",},
    {id: 'Y10', tile: "E1001",},
    {id: 'Y11', tile: "E1010",},
    {id: 'Y12', tile: "E1010",},
    {id: 'Y13', tile: "E1100",},
    {id: 'Y14', tile: "E0011",},
    {id: 'Y15', tile: "I0000",},
    {id: 'Y16', tile: "I0000",},
    {id: 'Y17', tile: "I0000",},
    {id: 'Y18', tile: "I0000",},
    {id: 'Y19', tile: "I0000",},
    {id: 'Y20', tile: "I0000",},
    {id: 'Y21', tile: "I0000",},
    {id: 'Y22', tile: "V",},
    {id: 'Y23', tile: "I0000",},
    {id: 'Y24', tile: "I0000",},
    {id: 'Y25', tile: "I0000",},
    {id: 'Y26', tile: "V",},
    {id: 'Z1', tile: "V",},
    {id: 'Z2', tile: "I0010",},
    {id: 'Z3', tile: "I0010",},
    {id: 'Z4', tile: "I0010",},
    {id: 'Z5', tile: "I1110",},
    {id: 'Z6', tile: "E0111",},
    {id: 'Z7', tile: "E0011",},
    {id: 'Z8', tile: "E0110",},
    {id: 'Z9', tile: "E0011",},
    {id: 'Z10', tile: "E0010",},
    {id: 'Z11', tile: "E1010",},
    {id: 'Z12', tile: "E1110",},
    {id: 'Z13', tile: "E0011",},
    {id: 'Z14', tile: "E1010",},
    {id: 'Z15', tile: "E0010",},
    {id: 'Z16', tile: "V",},
    {id: 'Z17', tile: "I0010",},
    {id: 'Z18', tile: "I0010",},
    {id: 'Z19', tile: "I0010",},
    {id: 'Z20', tile: "I0010",},
    {id: 'Z21', tile: "I0010",},
    {id: 'Z22', tile: "I0010",},
    {id: 'Z23', tile: "I0010",},
    {id: 'Z24', tile: "I0010",},
    {id: 'Z25', tile: "I0010",},
    {id: 'Z26', tile: "I0110",},       
]
        
        